import { Show, createSignal } from "solid-js";
import { ContactPage } from "./contact";
import { ExperienceFirstPage } from "./experience-first-page";
import { ExperienceSecondPage } from "./experience-second-page";
import { IntroPage } from "./intro";
import { LanguagePage } from "./language";
import { ResidencyPage } from "./residency";
import { TalkInfoPage } from "./talk-info";
import { TalkingTopicPage } from "./talking-topic";
import { ThankYouPage } from "../thank-you";
import { Cmp, state } from ":mods";
import { TutorSignup } from "../../api";
import { SignupTutorsViewProps } from "../../model";
import clone from "clone";

export function SignupTutors({ $, ...props }: SignupTutorsViewProps) {
  let signup_form_data = {};
  const [introDone, setIntroDone] = createSignal(false);
  const [talkingTopicDone, setTalkingTopicDone] = createSignal(false);
  const [contactDone, setContactDone] = createSignal(false);
  const [residencyDone, setResidencyDone] = createSignal(false);
  const [languageDone, setLanguageDone] = createSignal(false);
  const [ExperienceFirstDone, setExperienceFirstDone] = createSignal(false);
  const [ExperienceSecondDone, setExperienceSecondDone] = createSignal(false);
  const [talkInfoDone, setTalkInfoDone] = createSignal(false);

  const onIntroConfirm = async () => {
    setIntroDone(true);
  };
  const onTalkingTopicSubmit = async (values) => {
    signup_form_data = { ...signup_form_data, ...values };
    console.log(values);
    setTalkingTopicDone(true);
  };
  const onContactPageSubmit = async (values) => {
    signup_form_data = { ...signup_form_data, ...values };

    console.log(values);
    setContactDone(true);
  };
  const onResidencySubmit = async (values) => {
    signup_form_data = { ...signup_form_data, ...values };

    console.log(values);
    setResidencyDone(true);
  };
  const onLanguageSubmit = async (values) => {
    signup_form_data = { ...signup_form_data, ...values };

    console.log(values);
    setLanguageDone(true);
  };

  const onExperienceFirstPage = async (values) => {
    signup_form_data = { ...signup_form_data, ...values };

    console.log(values);
    setExperienceFirstDone(true);
  };
  const onExperienceSecondPage = async (values) => {
    signup_form_data = { ...signup_form_data, ...values };

    console.log(values);
    setExperienceSecondDone(true);
  };
  const onTalkInfoPage = async (values) => {
    signup_form_data = { ...signup_form_data, ...values };

    const formdata = new FormData();
    Object.entries(signup_form_data).forEach(([key, value]) => {
      if (value === undefined || value === null) {
        return;
      }
      if (value instanceof FileList) {
        if (value.length > 0) {
          // const file = clone(value[0]);
          formdata.append(key, value[0]);
        }
      } else {
        formdata.append(key, value as any);
      }
    });
    await TutorSignup(formdata)
      .then((res) => {
        $.actions.pushToast({ type: "success", message: `Application Submitted Successfully` });
        setTalkInfoDone(true);
      })
      .catch((error) => {
        Cmp.Alert.actions.pushToast({
          type: "error",
          message: error,
        });
      });
  };

  return (
    <main class="flex flex-col items-center justify-between h-80vh w-screen">
      <Show when={!introDone()}>
        <IntroPage onConfirm={onIntroConfirm} />
      </Show>
      <Show when={introDone() && !talkingTopicDone()}>
        <TalkingTopicPage $={$} onFormSubmit={onTalkingTopicSubmit} />
      </Show>
      <Show when={talkingTopicDone() && !contactDone()}>
        <ContactPage $={$} onFormSubmit={onContactPageSubmit} />
      </Show>
      <Show when={contactDone() && !residencyDone()}>
        <ResidencyPage $={$} onFormSubmit={onResidencySubmit} />
      </Show>
      <Show when={residencyDone() && !languageDone()}>
        <LanguagePage $={$} onFormSubmit={onLanguageSubmit} />
      </Show>
      <Show when={languageDone() && !ExperienceFirstDone()}>
        <ExperienceFirstPage $={$} onFormSubmit={onExperienceFirstPage} />
      </Show>
      <Show when={ExperienceFirstDone() && !ExperienceSecondDone()}>
        <ExperienceSecondPage $={$} onFormSubmit={onExperienceSecondPage} />
      </Show>
      <Show when={ExperienceSecondDone() && !talkInfoDone()}>
        <TalkInfoPage $={$} onFormSubmit={onTalkInfoPage} />
      </Show>
      <Show when={talkInfoDone()}>
        <ThankYouPage />
      </Show>
    </main>
  );
}
